<template>
  <app-layout>
    <div class="px-4 p-sm-5 container mh-100 d-flex flex-column">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Compte bancaire</h2>
      </div>

      <template v-if="isLoading">
        <b-spinner></b-spinner>
      </template>

      <template v-else>
        <div class="row">
          <div class="col-12">

            <div class="row">
              <div class="col-12" v-if="hasError">
                <div class="alert alert-danger">
                  <div>Une erreur interne s'est produite. Réessayez dans quelques instants.</div>
                </div>
              </div>
            </div>

            <p>Le produit de vos ventes sera reversé automatiquement par dépôt direct sur le compte bancaire suivant.</p>

            <div v-if="account && !isEditing">
              <bank-account-card
                :account="account"
                editable
                @edit-click="handleEdit"
              >
              </bank-account-card>

              <div v-if="account" class="mt-4">
                <button class="btn btn-primary" @click="handleCompletedStep('bankAccount')">Continuer</button>
              </div>
            </div>

            <div v-else>
              <div class="card mb-4">
                <div class="card-header">Informations du compte</div>
                <div class="card-body">
                  <v-dynamic-form
                    :fields="fields"
                    :initial-values="{}"
                    @change="handleChange"
                    :errors="formErrors"
                  ></v-dynamic-form>

                  <p class="mt-4 terms">
                    Nous utilisons la plateforme Stripe pour l'envoi des reversements aux vendeurs.
                    <br>En enregistrant votre compte bancaire, vous acceptez les <a href="https://stripe.com/en-ca/connect-account/legal/recipient" target="_blank">conditions Stripe applicables aux bénéficiaires</a> <fa-icon :icon="['fas', 'external-link-alt']" />.
                  </p>
                </div>
              </div>

              <div class="mb-4">
                <button-loader :disabled="isProcessing" :loading="isProcessing" @click="handleSubmit">
                  Enregistrer
                </button-loader>
              </div>
            </div>
          </div>
        </div>
      </template>

      <skip-onboarding-button />
    </div>
  </app-layout>
</template>

<script>
import userData from '@/mixins/user-data'
import onboarding from '@/mixins/onboarding'
import http from '@/utils/http'
import ButtonLoader from '@/components/common/ButtonLoader'
import BankAccountCard from '@/components/settings/BankAccountCard'
import useValidator from '@/mixins/useValidator'
import SkipOnboardingButton from '@/components/onboarding/SkipOnboardingButton'

export default {
  mixins: [userData, onboarding, useValidator],
  components: {
    BankAccountCard,
    ButtonLoader,
    SkipOnboardingButton
  },
  data () {
    return {
      isLoading: true,
      hasError: false,
      account: null,
      form: {},
      isProcessing: false,
      showFormErrors: false,
      isEditing: false
    }
  },
  computed: {
    fields () {
      return [
        {
          name: 'accountName',
          label: 'Nom du titulaire du compte',
          required: true
        },
        {
          name: 'transitNumber',
          label: 'Numéro de transit',
          required: true,
          helpText: 'Numéro à 5 chiffres'
        },
        {
          name: 'institutionNumber',
          label: "Numéro d'institution",
          required: true,
          helpText: 'Numéro à 3 chiffres'
        },
        {
          name: 'accountNumber',
          label: 'Numéro de compte',
          required: true
        }
      ]
    },
    formErrors () {
      return this.showFormErrors ? this.errors : {}
    },
    defaultValues () {
      return {}
    },
    validationRules () {
      return {
        accountName: 'required|min:2',
        transitNumber: 'required|bank-transit',
        institutionNumber: 'required|bank-institution',
        accountNumber: 'required|bank-account'
      }
    }
  },
  methods: {
    async fetchBankAccount () {
      this.isLoading = true

      try {
        const { data: account } = await http.get(`/v1/stores/${this.currentStoreId}/bank-accounts`)
        this.account = account
      } catch (e) {
        this.hasError = true
      } finally {
        this.isLoading = false
      }
    },
    handleEdit () {
      this.isEditing = true
    },
    handleChange (values) {
      this.validate(values, this.validationRules)
      this.form = { ...values }
    },
    async handleSubmit () {
      this.showFormErrors = Object.values(this.errors).length > 0

      if (this.showFormErrors) {
        return
      }

      this.isProcessing = true

      try {
        await http.put(`/v1/stores/${this.currentStoreId}/bank-accounts`, { ...this.form })

        this.handleCompletedStep('bankAccount')
      } catch (e) {
        this.$toasted.error('Une erreur s\'est produite. Vérifiez vos informations bancaires, puis réessayez.')
        this.isProcessing = false
      }
    }
  },
  created () {
    this.redirectIfStepDisabled('bankAccount')
    this.fetchBankAccount()
  }
}
</script>

<style lang="scss" scoped>
.terms {
  font-size: 13px;
  line-height: 20px;

  svg {
    width: 11px;
  }
}
</style>
