<template>
  <div class="d-flex align-self-center">
    <button class="btn btn-sm btn-link mt-auto" @click="handleSkip">
      Sauter ces étapes pour le moment
    </button>
  </div>
</template>

<script>
import http from '@/utils/http'
import userData from '@/mixins/user-data'

export default {
  mixins: [userData],
  methods: {
    async handleSkip () {
      try {
        await http.patch(`/v1/stores/${this.currentStoreId}/skip-onboarding`, { hasSkippedOnboarding: true })
        this.$store.commit('SKIP_REGISTRATION', true)
        this.$router.push('/')
        window.scrollTo(0, 0)
      } catch (e) {
        this.$toasted.error('Une erreur s\'est produite. Veuillez réessayer.')
      }
    }
  }
}
</script>
