<template>
  <app-layout>
    <div class="px-4 p-sm-5 container mh-100 d-flex flex-column">
      <div class="heading d-flex justify-content-between mb-5">
        <h2>Taxes de vente</h2>
      </div>

      <div class="row">
        <div class="col-12 col-lg-9 col-xl-6">
          <tax-registration-form layout="dropdown" @saved="handleCompletedStep('taxes')"></tax-registration-form>
        </div>
      </div>

      <skip-onboarding-button />
    </div>
  </app-layout>
</template>

<script>
import userData from '@/mixins/user-data'
import onboarding from '@/mixins/onboarding'
import TaxRegistrationForm from '@/components/settings/TaxRegistrationForm'
import SkipOnboardingButton from '@/components/onboarding/SkipOnboardingButton'

export default {
  mixins: [userData, onboarding],
  components: {
    TaxRegistrationForm,
    SkipOnboardingButton
  },
  created () {
    this.redirectIfStepDisabled('taxes')
  }
}
</script>
