<template>
  <div class="card">
    <div class="card-body">
      <dl class="row">
        <dt class="col-5">Nom du titulaire :</dt>
        <dd class="col-7">{{ account.account_holder_name }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-5">Banque :</dt>
        <dd class="col-7">{{ account.bank_name }}</dd>
      </dl>
      <dl class="row">
        <dt class="col-5">Numéro de compte :</dt>
        <dd class="col-7">**** **** {{ account.last4 }}</dd>
      </dl>

      <div v-if="editable" class="mt-3">
        <a href="" @click.prevent="$emit('edit-click')">Modifier</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    account: {
      type: Object,
      default () {
        return {}
      }
    },
    editable: {
      type: Boolean
    }
  }
}
</script>
